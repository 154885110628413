<template>
  <div
    class="d-flex flex-column flex h-100"
    :style="
      get(getLoggedInUser, 'role_type') === USER_ROLE_TYPES.STUDENT ||
      get(getLoggedInUser, 'role_type') === USER_ROLE_TYPES.SCHOOL
        ? 'min-height: calc(100vh - 350px) !important'
        : ''
    "
  >
    <router-view v-bind:layout-props="layoutProps" />
    <!-- <swipe-bottom-navigation
      :options="
        isRoutePublicSchlLanding ? publicRouteOptions : isPaidSchool ? studentPremiumRouteOptions : studentRouteOptions
      "
      v-model="selected"
      swiper-color="#FFFFFF"
      :background-color="get(getLoggedInUser, 'linked_entity.dark_color') || dark"
      icon-color="#FFFFFF"
      v-if="
        (get(getLoggedInUser, 'role_type') === USER_ROLE_TYPES.STUDENT || isRoutePublicSchlLanding) && isMobileScreen
      "
      :key="stdNavKey"
    >
      <template #icon="{ props }">
        <md-icon>{{ props.icon }}</md-icon>
      </template>
      <template #title="{ props }">
        <b>{{ props.title }}</b>
      </template>
    </swipe-bottom-navigation> -->

    <curved-bottom-navigation
      :options="isRoutePublicSchlLanding ? publicRouteOptions : studentRouteOptions"
      v-model="selected"
      :foreground-color="get(getLoggedInUser, 'linked_entity.dark_color') || dark"
      background-color="#FFFFFF"
      :icon-color="get(getLoggedInUser, 'linked_entity.dark_color') || dark"
      v-if="
        (get(getLoggedInUser, 'role_type') === USER_ROLE_TYPES.STUDENT || isRoutePublicSchlLanding) && isMobileScreen
      "
      :key="stdNavKey"
    >
      <template #icon="{ props }">
        <md-icon>{{ props.icon }}</md-icon>
      </template>
      <template #title="{ props }">
        <b>{{ props.title }}</b>
      </template>
      <template #child-icon="{ props }">
        <md-icon>{{ props.icon }} </md-icon>
      </template>
      <template #child-title="{ props }">
        <div class="pl-2 pr-2">{{ props.title }}</div>
      </template>
    </curved-bottom-navigation>
  </div>
</template>

<script>
import {
  CurvedBottomNavigation
  // SwipeBottomNavigation
} from 'bottom-navigation-vue';
import { get, isEmpty, findIndex } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { SCH_PUBLIC_PORTAL_LINKS, USER_ROLE_TYPES } from '@/common/constants';

export default {
  components: {
    CurvedBottomNavigation
    // SwipeBottomNavigation
    // AppSettings,
  },
  props: {
    layoutProps: {
      type: Object
    }
  },
  data() {
    return {
      USER_ROLE_TYPES,
      dark: null,
      selected: 1,
      isPaidSchool: null,

      stdNavKey: 'stdRoutes',

      publicRouteOptions: [
        { id: 1, icon: 'school', title: 'For Students', path: { name: 'sch-public-home' } },
        { id: 2, icon: 'business', title: 'For Employers', path: { name: 'sch-public-parnerships' } }
      ],
      windowWidth: window.innerWidth
    };
  },

  methods: {
    ...mapActions('school', ['getSchoolFromCurrentDomain']),
    ...mapActions('navbarConfig', ['updateNavbarBrandDetails']),
    get,

    handleResize() {
      this.windowWidth = window.innerWidth;
    }
  },

  watch: {
    '$route.name': {
      immediate: true,
      handler(value) {
        let routeIndex = findIndex(
          this.isRoutePublicSchlLanding ? this.publicRouteOptions : this.studentRouteOptions,
          opt =>
            opt.childs && opt.childs.length
              ? opt.childs.find(route => route.path.name === value)
              : get(opt, 'path.name') === value
        );
        if (routeIndex > -1) {
          this.selected = routeIndex + 1;
        }
      }
    },

    studentRouteOptions: {
      immediate: true,
      handler(value) {
        let routeIndex = findIndex(
          this.isRoutePublicSchlLanding ? this.publicRouteOptions : this.studentRouteOptions,
          opt =>
            opt.childs && opt.childs.length
              ? opt.childs.find(route => route.path.name === value)
              : get(opt, 'path.name') === value
        );
        if (routeIndex > -1) {
          this.selected = routeIndex + 1;
        }
      }
    },

    getPaymentStatus: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) {
          this.isPaidSchool = value;
          this.stdNavKey = 'stdPremRoutes';

          // this.$nextTick(() => {
          //   let routeIndex = findIndex(
          //     this.studentPremiumRouteOptions,
          //     (opt) => get(opt, 'path.name') === this.$route.name
          //   );
          //   if (routeIndex !== -1) this.selected = routeIndex + 1;
          // });
        }
      }
    }
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);
    // if (
    //   get(this.getLoggedInUser, 'role_type') === USER_ROLE_TYPES.STUDENT ||
    //   get(this.getLoggedInUser, 'role_type') === USER_ROLE_TYPES.SCHOOL
    // ) {
    //   const school = await this.updateNavbarBrandDetails();
    // }

    const school = await this.getSchoolFromCurrentDomain({ storeSchool: true });
    if (!isEmpty(school)) {
      this.$store.commit('school/updateCurrentSchool', school);
      this.dark = school.dark_color;
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('school', ['getPaymentStatus']),

    isRoutePublicSchlLanding() {
      return SCH_PUBLIC_PORTAL_LINKS.includes(this.$route.name);
    },
    isMobileScreen() {
      return this.windowWidth <= 575;
    },

    careerPathRoutes() {
      return get(this.getLoggedInUser, 'linked_entity.std_portal_uni')
        ? {
            id: 4,
            icon: 'local_hospital',
            title: 'Pathways',
            childs: [
              { id: 301, icon: 'business_center', title: 'Orgs', path: { name: 'student-org-listing' } },
              { id: 302, icon: 'account_balance', title: 'Unis', path: { name: 'student-uni-listing' } }
            ]
          }
        : {
            id: 4,
            icon: 'local_hospital',
            title: 'Career Paths',
            path: { name: 'student-org-listing' }
          };
    },

    studentPremiumRouteOptions() {
      return [
        { id: 1, icon: 'home', title: 'Home', path: { name: 'student-dashboard' } },

        // Replaced with Community page
        // { id: 2, icon: 'question_answer', title: 'Questions', path: { name: 'student-questions' } },
        // { id: 3, icon: 'videocam', title: 'Skills', path: { name: 'student-skill' } },

        {
          id: 2,
          icon: 'work',
          title: 'Jobs',
          childs: [
            { id: 356, icon: 'business_center', title: 'All', path: { name: 'student-jobs-listing' } },
            { id: 357, icon: 'account_balance', title: 'Featured', path: { name: 'student-local-jobs-listing' } },
            { id: 358, icon: 'business', title: 'Companies', path: { name: 'student-employers-list' } }
          ]
        },
        { id: 3, icon: 'stars', title: 'Sponsorships', path: { name: 'student-scholarships-listing' } },
        // { id: 4, icon: 'business', title: 'Companies', path: { name: 'student-employers-list' } },
        this.careerPathRoutes

        // { id: 6, icon: 'apps', title: 'Resources', path: { name: 'student-community' } },
      ];
    },

    studentRouteOptions() {
      return [
        { id: 1, icon: 'home', title: 'Home', path: { name: 'student-dashboard' } },
        { id: 2, icon: 'info', title: 'My Info', path: { name: 'student-info' } }
        // {
        //   id: 2,
        //   icon: 'work',
        //   title: 'Jobs',
        //   childs: [
        //     { id: 345, icon: 'business_center', title: 'All', path: { name: 'student-jobs-listing' } },
        //     { id: 346, icon: 'account_balance', title: 'Featured', path: { name: 'student-local-jobs-listing' } },
        //     { id: 347, icon: 'business', title: 'Company', path: { name: 'student-employers-list' } }
        //   ]
        // },
        // { id: 3, icon: 'stars', title: 'Sponsorships', path: { name: 'student-scholarships-listing' } },
        //    { id: 4, icon: 'business', title: 'Companies', path: { name: 'student-employers-list' } },
        // this.careerPathRoutes

        // { id: 6, icon: 'apps', title: 'Resources', path: { name: 'student-community' } },
      ];
    }
  }
};
</script>
